.main {
}

.content {
  width: 1120px;
  margin: 0 auto;
  display: flex;
  padding: 92px 0;
  justify-content: space-between;
}

.left {
  width: 472px;
}

.right {
  width: 520px;
  min-height: 586px;
  background: #FFFFFF;
  border: 1px solid #E8EAF2;
  box-shadow: 0px 2px 10px rgba(0, 80, 125, 0.08);
  border-radius: 12px;
  padding: 32px;
}

.fonesafe {
  display: flex;

}

.fonesafe img {
  width: 100px;
  margin-right: 24px;;
}

.fonesafe .info h2 {
  font-size: 16px;
  line-height: 24px;
}

.fonesafe .info .price {
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  color: #FF5A00;
  margin-top: 8px;
}

.list {
  margin-top: 20px;;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.item .label {
  font-size: 16px;
  line-height: 24px;
}

.item .value {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.coupon {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6A51FF;
  padding: 12px 0;
  border-bottom: 1px solid #E8EAF2;
  margin-top: 12px;
}

.renewal {
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
}

.renewal .toggle {
  color: #6A51FF;
  font-weight: bole;;
  font-size: 18px;
}

.notice {
  padding: 12px;
  border: 1px solid #D6DAE0;
  border-radius: 4px;
  margin-top: 10px;
}

.notice-tip {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.icon-list {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.icon-list img {
  height: 32px;
}

@media (max-width: 960px) {
  .content {
    width: 100%;
    flex-direction: column-reverse;
    padding: 24px 10px;
    box-sizing: border-box;
  }

  .left {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .right {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    min-height: auto;
  }

  .icon-list img {
    height: 20px;
  }
}