.footer-container {
	background: #202124;
	color: #fff;
}
.top {
	height: 72px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.bottom {
}
.bottomLogo {
	height: 24px;
}
.copyRight {
	font-size: 12px;
	line-height: 18px;
	color: #fff;
	opacity: 0.6;
	border-top: 1px solid rgba(159, 154, 174, .2);
	padding-top: 12px;
	padding-bottom: 24px;
}
.wrapper {
	flex-direction: column;
}
.links {
	display: flex;
	align-items: center;
	height: 72px;
}
.link {
	color: #fff;
	display: block;
	font-size: 14px;
	margin-left: 32px;
}
.bottom-mobile {
	display: none;
}

@media (max-width: 960px) {
	.bottom {
		display:none;
	}
	.bottomLogo {
		height: 36px;
	}
	.bottom-mobile {
		display: block;
		opacity: .8;
	}
	.copyRight {
		padding-bottom: 12px;
		border: none;
	}
	.top {
		flex-direction: column;
			align-items: flex-start;
			height: auto;
	}
	.links {
		flex-direction: column;
			align-items: flex-start;
			height: auto;
			border-top: 1px solid #666;
			    width: 100%;
    padding-top: 20px;
	}
	.links li {
		margin-bottom: 12px;
	}
	.link {
		color: #fff;
		margin-left: 0;
	}
}