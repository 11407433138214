.wrapper {
	height: 72px;
	display: flex;
	justify-content: center;
	box-shadow: 0px 2px 10px rgba(0, 80, 125, 0.08);
}
.middle {
	width: 1120px;
	margin: 0 auto;
	display: flex;
}
.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #fff;
}
.logo {
	height: 24px;
}
.website-logo {
	height: 18px;
}
.linkText {
	font-size: 16px;
	color: #202124;
	padding: 0 30px;
	line-height: 72px;
	font-weight: 600;
}
.navLink {
	height: 72px;
}
.linkUl {
	flex-direction: row;
	display: flex;
	align-items: center;
}
.button {
	background: #6A51FF;
	/* 小投影 */
	box-shadow: 0px 2px 10px rgba(0, 80, 125, 0.08);
	border-radius: 4px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	height: 36px;
	line-height: 36px;
	padding: 0 24px;
}
.menu {
	display: none
}
.menu-image {
	width: 32px;
	height: 32px;
}
.mask {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,.5);
	position: absolute;
	top: 0;
	left: 0;
}

.line {
	width: 1px;
	height: 16px;
	background-color:  #606367;
	margin: 0 8px;
}

.logo-wrapper {
	display: flex;
    align-items: center;
}

@media (max-width: 960px) {
  .middle {
		width: 100%;
		padding: 12px;
		box-sizing: border-box;
	}
	.linkUl {
		display: none;
	}
	.linkUl-mobile {
		display: block;
	}
	.menu {
		display: block;
	}
	.mask-container {
    background: #fff;
	}
	.linkUl {
		flex-direction: column;
    align-items: flex-start;
    padding: 10px;
	}
	.mask-top {
		padding: 10px 10px;
		border-bottom: 1px solid #f1f1f1;
		text-align: right;
	}
	.close {
		width: 20px;
		height: 20px;
	}
	.navLink {
		height: 50px;
	}
	.linkText {
		padding: 0 20px;
		line-height: 50px;
	}
	button {
		height: 40px;
		line-height: 40px;
		margin-top: 10px;
		margin-left:20px;
	}
}
