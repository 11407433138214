html, body, p, ul, li, h1, h2 {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
}

body {

  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   		color: '#202124';

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  width: 1300px;
  max-width: 90vw;
  margin: 0 auto;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

button {
  background: rgb(106, 81, 255);
  box-shadow: rgb(0 80 125 / 8%) 0px 2px 10px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  padding: 0px 24px;
  display: block;
  border: none;
  opacity: 0.95;
}

button:hover, button:active {
  font-weight: bold;
  opacity: 1;
}

.main-button {
  display: block;
  width: 300px;
  height: 68px;
  background: #8C79FF;
  border-radius: 100px;
  font-weight: 800;
  line-height: 68px;
  font-size: 24px;
  line-height: 68px;
  text-align: center;
  color: #FFFFFF;
}

.main-button-white {
  background: #fff;
  color: #8C79FF;
}

.main-button:hover {
  background: #6A51FF;
  box-shadow: 0px 8px 24px rgba(63, 23, 179, 0.2);
}

.main-button-white:hover {
  color: #fff
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: #6A51FF;
  width: 32px;
  height: 2px;
  border-radius: 0;
}
